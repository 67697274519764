/*.sidenav {
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
}
.main {
    margin-left: 160px;
    padding: 0px 10px;
}*/

.mainContainer {
    margin: 0 10px 0 10px;
}
.noAuthMainContainer {
    margin: 0 10px 0 10px;
}
@media (min-width: 768px) {
    .mainContainer { margin: 0 10px 0 260px; }
}
    /*#main {
    margin: 0 0 50px 0;
}

#wrapper {
    margin: 0 0 0 250px;
    -moz-transition: margin-left ease .25s;
    -o-transition: margin-left ease .25s;
    -webkit-transition: margin-left ease .25s;
    transition: margin-left ease .25s;
}

#sidebar {
    background: #2a2c36;
    color: #eee;
    position: fixed;
    height: 100%;
    width: 250px;
    overflow: hidden;
    left: 0;
    margin: 0;
    -moz-transition: left ease .25s;
    -o-transition: left ease .25s;
    -webkit-transition: left ease .25s;
    transition: left ease .25s;
}

#sidebar.hide-sidebar {
    left: -250px;
    -moz-transition: left ease .25s;
    -o-transition: left ease .25s;
    -webkit-transition: left ease .25s;
    transition: left ease .25s;
}*/