﻿@import "Variables.scss";

/*.card-title {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
    font-size: 16px;
    //font-weight: bold;
    color: black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}*/

.card-form {
    border: 1px solid $light-color !important;
}

.card-form .card-header {
    color: black;
    font-size: 1rem;
    padding: 0.5rem 0.75rem !important;
}


.card {
    background-color: white;
    margin-bottom: .75rem;
}

.card-body {
    background-color: white;
}
.card .card-header, .card .card-header .mr-auto {
    background-color: $light-blue-color;
    margin-top: auto;
    margin-bottom: auto;
    font-size: $header-font-size;
    border: 0px !important;
}

.card-body-table {
    padding: 0px;
}

.card-header {
    color: black;
    font-weight: bold;
    font-size: 1rem;
    border: 1px solid $light-blue-color !important;
    padding: 0.5rem 0.75rem !important;
}
.card-btn.group {
    font-weight: normal !important;
}
.card-btn-group .btn {
    margin-left: 3px;
    margin-right: 3px;
}

.card-btn-group .card-button-group-item {
    margin-left: 3px;
    margin-right: 3px;
    font-weight: normal !important;
}

.card-btn-group *:first-child {
    margin-left: 0 !important;
}

.card-btn-group *:last-child {
    margin-right: 0 !important;
}

.card-page-level {
    height: 100%;
    margin-bottom: 20px;
}
