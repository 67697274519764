@import "Variables.scss";

.finditem-datatable-info-subtable, .datatable-info-subtable > td {
    background-color: #fff;
    padding: 5px 10px 10px 10px;
    border-left: 20px solid $light-blue-color;
    border-right: 20px solid $light-blue-color;
    border-bottom: 20px solid $light-blue-color;
}

.finditem-datatable-info-instrument-subtable, .datatable-info-instrument-subtable > td {
    background-color: #fff;
    padding: 5px 10px 10px 10px;
    border-left: 20px solid $light-blue-color;
    border-bottom: 20px solid $light-blue-color;
}
