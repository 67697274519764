﻿
@import "Variables.scss";
@import "WebcamCapture.scss";

.context-container {
    background-color: $medium-color;
    margin-right: 0;
    margin-left: 0;
    color: white;
    padding: 6px;
    width: 100%;
}

.context-container-or-portal {
    min-height: 55px;
    margin-top: -0.4rem;
    margin-bottom: 0.4rem;
    color: #57585B;
}

.tray-selectors {
    min-height: 11rem;
    max-height: 11rem;
    overflow-y: scroll;
    border: 1px solid #efedef;
    margin: 6px;
}

.tight-list-group {
    padding: 2px 4px;
}

.vendor-button {
    margin: 6px !important;
    flex-basis: 22% !important;
    border-radius: 0.3rem !important; //prevents toggle button group look
    max-width: 250px
}

.vendor-tray {
    margin-right: .3rem;
}

.vendor-search-limit {
    font-style: italic;
    font-size: .9rem;
}

.linebreaks {
    white-space: pre-wrap;
}

.check-box-lil-bigger input[type=checkbox] {
    width: 1.1rem;
    height: 1.1rem;
}

.barcode-linked-cases {
    width: 10rem;
}

.linked-cases {
    position: absolute;
    margin-left: 8.0rem !important;
}

.shrink-col {
    padding-right: 1rem;
    margin-top: 1.5rem;
}

.case-search-fields {
    width: 100%;
}

.case-search-fields td {
    padding: .4rem;
}

.case-search-fields td #patientInitials {
    width: 50%;
}

.vr {
    display: inline;
    border-right: 1px solid #80808063;
    padding: 0px 5px;
    margin-bottom: 5px;
}

.find-items-label {
    margin-bottom: auto;
    margin-top: auto;
    margin-right: .25rem;
}

.loaner-counts {
    font-weight: normal;
    margin-left: .25rem;
}

.expected-items{
    font-size: 1.25rem;
    color: $black-color;
}