.loading-button-active {
    opacity: 0.4;
    transition-delay: 800ms;
    transition-property: opacity;
  }
  .loading-button {
    opacity: 1;
    transition-delay: 100ms;
    transition-property: opacity;
  }
  
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  
  .loading-container-active {
    opacity: 1;
    transition: opacity 100ms ease-in-out 800ms;
  }