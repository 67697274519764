.table-fit {
    white-space: nowrap;
    width: 1%;
    }
.cellCenterContent {
    text-align: center;
}

.table-AssingedItems th, table td {
    vertical-align: middle !important;
}

.datatable-info-assingedItems, .datatable-info > th, .datatable-info > td {
    padding-left: 115px;
}


.input-assingeditems {
    width: 52px !important;
}