﻿@import "Variables.scss";
@import "WebcamCapture.scss";

.modal{
    z-index: 2002 !important;
}

.tooltip {
    z-index: 2003;
}

.modal .modal-header {
    background-color: $light-blue-color;
    margin-top: auto;
    margin-bottom: auto;
    font-size: $header-font-size;
}
.modal .modal-header-warning {
    background-color: $exclamation-color;
    margin-top: auto;
    margin-bottom: auto;
    font-size: $header-font-size;
    color: white;
}

.modal-content {
    min-width: 570px;
}

.modal .modal-body-warning {
    color: $black-color;
    display: inline-block;
    object-fit: scale-down;
}

.modal .modal-message-warning-container {
    display: flex;
    padding: 1rem;
}

.modal .modal-message-warning-container img {
    width: 100%;
}
.modal .modal-message-warning {
    padding-left: 1rem;
}
.modal-body-table {
    padding: 0px;
}

.modal-backdrop {
    z-index: 2001 !important;
}

.modal-tooltip{
    z-index: 2002 !important;
}
.modal-scroll {
    max-height: 40rem;
}