﻿.image-record {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.images-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 260px;
    background: lightgray;
}

.capture-placeholder {
    width: 100%;
    max-width: 650px;
    max-height: 490px;
    height: auto;
    background: lightgray;
}

.column-centered-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row-centered-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.no-webcam-warning {
    display: block;
    position: absolute;
    width: 250px;
    height: 180px;
    text-align: center;
}
