﻿.tab-up {
    margin-top: 50px
}

.tab-content {
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: 100%;
}

.tab-content-form {
    padding: 12px;
}

.tab-content-table {
    padding: 0px;
}

.tab-list-container {
    width: 100% !important;
}
