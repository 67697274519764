.datatable-info, .datatable-info > tr {
    background-color: #cfe1f3;
    color: black;
}
.datatable-info, .datatable-info > th, .datatable-info > td {
    background-color: #cfe1f3;
    color: black;
    padding-bottom: 2px;
}

.datatable-span-collapsed {
    margin-top: 0em;
    margin-bottom: 0em;
}
.datatable-span-expanded {
    margin-top: 0.1em;
    margin-bottom: 0em;
    color: white;
}