.gi-0-75x {
    font-size: 0.75em;
}
.gi-1x {
    font-size: 1em;
}

.gi-1-5x {
    font-size: 1.5em;
}
.gi-2x {
    font-size: 2em;
}

.gi-3x {
    font-size: 3em;
}

.gi-4x {
    font-size: 4em;
}

.gi-5x {
    font-size: 5em;
}

.mr-1 {
    margin: 1rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(235,237,239, 0.4) !important;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(235,237,239, 0.8) !important;
}

.glyphCircle {
    color: grey;
    padding: .4em;
    border-radius: 50%;
    border: 3px solid grey;
}

.glyphSolidCircle {
    background-color: grey;
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    border: 3px solid grey;
}

.show-hand {
    cursor: pointer;
}

.user-context {
    padding: 10px 16px;
    color: white;
}

.user-context-name {
    font-weight: bold;
}

.organization-section-header {
    font-weight: bold;
}

.header-text {
    font-size: 1.5em;
    padding-top: 10px;
}

.table-fit {
    white-space: nowrap;
    width: 1%;
    flex-basis: auto; /* default value */
    flex-grow: 1;
}
.cellCenterContent {
    text-align: center;
}

.wide-tooltip > .tooltip-inner {
    max-width: 100%;
}
