﻿#sideNavBar .nav {
    //width: 100%;
    width: 100%;
    flex-direction: column;
    min-height: 60%;
    padding: 0;
}

#sideNavBar .nav ul {
    float: none;
}

#sideNavBar .nav .nav-link {
    float: none;
    font-size: 15px;
    //margin: 6px;
}

#sideNavBar .nav .nav-link:hover {
    background-color: #016ac9;
    color: #fff;
}

#sideNavBar .nav-link.active {
    color: #fff;
    background: rgba(154,155,156,0.2);
}

#sideNavBar .nav-link {
    padding: 10px 16px;
    display: block;
    width: 100%;
    //padding: 0.75rem 1rem;
    white-space: nowrap;
    color: #fff;
    text-decoration: none;
    background: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
}

#sideNavBar .nav-link .nav-icon {
    display: inline-block;
    width: 1.09375rem;
    margin: 0 0.5rem 0 0;
    //font-size: 0.875rem;
    color: #73818f;
    text-align: center;
}

.sidebar .logo-border {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-top:auto;
    margin-bottom:.5rem;
}

.sidebar .logo-border .ls360Logo {
    width: 100%;
    height: 100%;
    border-style: ridge;
    border-width: .25rem;
}

.sidebar .nav-link.active .nav-icon {
    color: #6699CC !important;
}

.sidebar .nav-link:hover .nav-icon {
    color: #fff !important;
}

.navbar-text {
    padding-right: 0.6rem;
}
