﻿
$dark-color: #545859;
$light-color: #545859;
$medium-color: #545859;
$black-color: black;
$gray-color: lightgray;
$green-color: darkgreen;
$light-blue-color: #cfe1f3;
$background-color: #ebedef;
$red-color: #ff0000;
$exclamation-color: #f45a52;
$steris-blue: #5aaaff;

$header-font-size: 1.15rem;